import axios from "axios";
import { MDBCard, MDBCardTitle, MDBModal, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import AppConfig from "../../config";

export const getUser = async (username: string) => {
    let data = await axios({
        method: 'get',
        baseURL: AppConfig.apiUrl,
        url: `/v1/ubisoft/users/${username}`
    });
    return data.data;
}

export const getStatus = async (username: string) => {
    let data = await axios({
        method: 'get',
        baseURL: AppConfig.apiUrl,
        url: `/v1/ubisoft/users/${username}/onlineStatus`
    });
    return data.data;
}

export const getClub = async (username: string) => {
    let data = await axios({
        method: 'get',
        baseURL: AppConfig.apiUrl,
        url: `/v1/ubisoft/users/${username}/club`
    });
    return data.data;
}

export const getProgression = async (username: string) => {
    let data = await axios({
        method: 'get',
        baseURL: AppConfig.apiUrl,
        url: `/v1/ubisoft/users/${username}/progression`
    });
    return data.data;
}

export const getConnections = async (username: string) => {
    let data = await axios({
        method: 'get',
        baseURL: AppConfig.apiUrl,
        url: `/v1/ubisoft/users/${username}/graphql`
    });
    return data.data;
}

export const returnNotFound = async (username: string) => {
    return alert(`The user ${username} cannot be found.`)
}