import { Component } from "react";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBCardHeader, MDBCardFooter, MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow, MDBCardOverlay } from 'mdb-react-ui-kit';
import moment from "moment";
import AppConfig from "../../config";
import { getInfo } from "./TwitterAPI";
import { arrToString, stringToString } from "../spotify/SpotifyAPI";
export default class FetchUser extends Component {

    state = {
        loading: true,
        accountName: "Digneety",
        profiles: null,
    }


    newAccountNameChanged = (event: any) => {
        this.setState({ accountName: event.target.value });
    }


    search = async () => {

        getInfo(this.state.accountName).then((response => this.setState({ profiles: response["profileInformation"], loading: false }))).catch(() => null);
    }


    render() {
        return (
            <>
                <>
                    <div className="d-flex justify-content-center">
                        <MDBCard className="bg-dark text-spotify w-25 p-2 mt-5">
                            <MDBCardHeader>
                                Search for a Twitter profile.
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol sm="9">
                                        <MDBInput label='Username' type='text' onChange={this.newAccountNameChanged} contrast />
                                    </MDBCol>
                                    <MDBCol sm="3">
                                        <MDBBtn onClick={this.search} color="success"><MDBIcon fas icon='search' /> Search</MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <br />
                </>
                <div>
                    {this.state.loading ? (
                        null) : (
                        <>
                            <div className="d-flex justify-content-center">
                                <MDBCard style={{ color: 'white' }} background="dark" border="secondary" className="p-3">
                                    <MDBCardImage src={this.state.profiles?.["bannerUrl"]} overlay alt='...' style={{ width: '80rem' }} />
                                    <MDBCardOverlay>
                                        <img className="rounded-circle border border-secondary border-2" src={this.state.profiles?.["imageUrl"]} style={{ width: '15rem', zIndex: '1' }} />
                                    </MDBCardOverlay>
                                    <MDBCardBody>
                                        <MDBCardTitle className="text-secondary">{this.state.profiles?.["handle"]}</MDBCardTitle>
                                        <MDBCardText>
                                            <div><a className="text-secondary">ProfileId: </a>{this.state.profiles ? this.state.profiles["profileId"] : null}</div>
                                            <div><a className="text-secondary">Description: </a>{this.state.profiles ? this.state.profiles["description"] : null}</div>
                                            <div><a className="text-secondary">Followers: </a>{this.state.profiles ? this.state.profiles["followers"] : null}</div>
                                            <div><a className="text-secondary">Following: </a>{this.state.profiles ? this.state.profiles["following"] : null}</div>
                                            <div><a className="text-secondary">Location: </a>{this.state.profiles ? this.state.profiles["location"] : null}</div>
                                            <div><a className="text-secondary">Verified: </a>{this.state.profiles ? stringToString(this.state.profiles["isVerified"]) : null}</div>
                                            <div><a className="text-secondary">Account Created: </a>{this.state.profiles ? moment.utc(this.state.profiles["createdAt"]).format('DD.MM.YYYY HH:mm:ss') : null}</div>
                                            <div><a className="text-secondary">Private: </a>{this.state.profiles ? stringToString(this.state.profiles["isProtected"]) : null}</div>
                                            <div><a className="text-secondary">Withheld In: </a>{this.state.profiles ? arrToString(this.state.profiles["withheldIn"]) : null}</div>
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
}