
import React from 'react'

import { MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon } from 'mdb-react-ui-kit'

const SingleTweet = (props: any) => {
    return (
        <>
            <MDBCard className='bg-dark text-spotify '>
                <MDBCardHeader>
                    <img className="rounded-circle me-2" src={props.image} alt="picture" style={{ maxHeight: '2rem' }} />
                    <a className="twitterHandle" href={`https://twitter.com/${props.handle}`}>{props.handle}</a>
                    <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#55acee', float: 'right' }} target="_blank" href={"https://twitter.com/" + props.handle + "/status/" + props.tweetId}>
                        <MDBIcon fab icon='twitter' size='lg' />
                    </MDBBtn>
                </MDBCardHeader>
                <MDBCardBody className='text-spotify'>{props.content}</MDBCardBody>
            </MDBCard>
            <br />
        </>
    )
}

export default SingleTweet