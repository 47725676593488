import axios from "axios";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSpotify, arrToString, handleLogin } from "./SpotifyAPI";


interface Artists {
    href: string;
    items: [];
    limit: number;
    next: string;
    offset: number;
    previous: any;
    total: number;
}

const ARTIST_ENDPOINT = "https://api.spotify.com/v1/me/top/artists?time_range=short_term&limit=10&offset=5";

const SpotifyArtists = () => {

    const [token, setToken] = useState<string | null>(null);
    const [data, setData] = useState<Artists | null>(null);

    useEffect(() => {
        useSpotify();
        if (localStorage.getItem("accessToken")) {
            setToken(localStorage.getItem("accessToken"));
        }
    }, []);

    const handleTop = () => {
        axios.get(ARTIST_ENDPOINT, {
            headers: {
                Authorization: "Bearer " + token,
            },
        }).then(response => {
            setData(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            {localStorage.getItem("accessToken") ?
                <>
                    <div className="text-center p-3">
                        <a style={{ textTransform: 'uppercase' }} key="topArt" className="ms-4 spotify" onClick={handleTop}>Get Top Artists</a>
                    </div>
                </> :
                <div className="text-center p-3">
                    <a style={{ textTransform: 'uppercase' }} onClick={handleLogin} className="spotify hover-shadow"><MDBIcon fab icon='spotify' className="me-2" />Login with spotify</a>
                </div>}
            <div className="d-flex justify-content-center p-3 smooth-scroll">
                <MDBRow className="bg-dark">
                    {data?.items ? data.items.map((artist: any) =>
                        <>
                            <MDBCol size="md-3" key={artist.id}><img src={artist.images[2].url} style={{ width: '6rem', height: '6rem' }} alt="cover" /></MDBCol>
                            <MDBCol size="md-3" key={artist.id} className="text-spotify"><a href={`https://open.spotify.com/artist/${artist.id}?si=3a1acf056a194e3c`}>{artist.name}</a></MDBCol>
                            <MDBCol size="md-4" key={artist.id} className="text-spotify">Gernes: {arrToString(artist.genres)}</MDBCol>
                        </>) : null}
                </MDBRow>
            </div>
        </>
    )
}
export default SpotifyArtists;