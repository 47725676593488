import { Component } from "react";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBCardHeader, MDBCardFooter, MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import moment from "moment";
import AppConfig from "../../config";
import { getClub, getConnections, getProgression, getStatus, getUser, returnNotFound } from "./UbisoftAPI";
import { arrToString } from "../spotify/SpotifyAPI";
export default class FetchUser extends Component {

    state = {
        loading: true,
        onlineStatuses: [],
        accountName: "Digneety",
        profiles: [],
        club: [],
        progression: null,
        connections: [],
        realConnections: [],
        profileNames: [],
    }


    newAccountNameChanged = (event: any) => {
        this.setState({ accountName: event.target.value });
    }


    search = async () => {
        await getUser(this.state.accountName).then((response => this.setState({ profiles: response["profiles"], loading: false }))).catch(() => returnNotFound(this.state.accountName));
        await getStatus(this.state.accountName).then((response => this.setState({ onlineStatuses: response["onlineStatuses"], loading: false }))).catch(() => null);
        await getClub(this.state.accountName).then((response => this.setState({ club: response, loading: false }))).catch(() => null);
        await getProgression(this.state.accountName).then((response => this.setState({ progression: response, loading: false }))).catch(() => console.log("Profile not found."));
        await getConnections(this.state.accountName).then((response) => this.setState({ connections: response["data"]["users"] })).catch(() => null);
        //console.log(this.state.connections[0]["networks"]["edges"])
        let nwe = this.state.connections[0]["networks"]["edges"];
        console.log(Object.keys(nwe).map((_key, index) => nwe[index]["node"]["id"]));
        let rc = Object.keys(nwe).map((_key, index) => nwe[index]["node"]["id"]);
        let rn = Object.keys(nwe).map((_key, index) => nwe[index]["meta"]["name"]);
        this.setState({ realConnections: rc, profileNames: rn });
    }


    render() {
        return (
            <>
                <>
                    <div className="d-flex justify-content-center">
                        <MDBCard className="bg-dark text-spotify w-25 p-2 mt-5">
                            <MDBCardHeader>
                                Search for a user.
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol sm="9">
                                        <MDBInput label='Username' type='text' onChange={this.newAccountNameChanged} contrast />
                                        <MDBBtn onClick={this.search} color="success"><MDBIcon fas icon='search' /> Search</MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <br />
                </>
                <div>
                    {
                        this.state.loading ? (
                            null) : (
                            <>
                                <div className="d-flex justify-content-center">
                                    <MDBCard style={{ color: 'white', width: '50rem' }} background="dark" border="secondary" className="p-3">
                                        <div className="text-center">
                                            <MDBCardImage className="rounded-circle border border-secondary border-2" src={this.state.profiles[0]["imageUrl"]} fluid alt='...' />
                                        </div>
                                        <MDBCardBody>
                                            <MDBCardTitle className="text-secondary">{this.state.profiles[0]["nameOnPlatform"]}</MDBCardTitle>
                                            <MDBCardText>
                                                <div><a className="text-secondary">Level: </a>{this.state.progression ? this.state.progression["level"] : null}</div>
                                                <div><a className="text-secondary">Status: </a>{this.state.onlineStatuses ? Object.keys(this.state.onlineStatuses).map((_key, _index) => this.state.onlineStatuses[0]["onlineStatus"]).join(", ") : null}</div>
                                                <div><a className="text-secondary">Platforms: </a>{this.state.realConnections ? this.state.realConnections.join(", ") : null}</div>
                                                <div><a className="text-secondary">Names: </a>{this.state.profileNames ? this.state.profileNames.join(", ") : null}</div>
                                                <div><a className="text-secondary">Other Platforms: </a>{this.state.profiles ? Object.keys(this.state.profiles).map((_key, index) => this.state.profiles[index]["platformType"]).join(", ") : null}</div>
                                                <div><a className="text-secondary">Other Names: </a>{this.state.profiles ? Object.keys(this.state.profiles).map((key, index) => this.state.profiles[index]["nameOnPlatform"]).join(", ") : null}</div>
                                                <div><a className="text-secondary">Units: </a>{this.state.club ? Object.keys(this.state.club).map((_key, index) => this.state.club[index]["units"]) : null}</div>
                                                <div><a className="text-secondary">Units Earned: </a>{this.state.club ? Object.keys(this.state.club).map((_key, index) => this.state.club[index]["unitsEarned"]) : null}</div>
                                                <div><a className="text-secondary">Units Spent: </a>{this.state.club ? Object.keys(this.state.club).map((_key, index) => this.state.club[index]["unitsSpent"]) : null}</div>
                                                {/* <div><a className="text-secondary">Creation Date: </a><>{this.state.club ? moment.utc(Object.keys(this.state.club).map((key, index) => this.state.club[0]["creationDate"])).format('DD.MM.YYYY') : null}</></div> */}
                                            </MDBCardText>
                                        </MDBCardBody>
                                        <MDBCardFooter>The Creation date may be inaccurate.</MDBCardFooter>
                                    </MDBCard>
                                </div>
                            </>
                        )}
                </div>
            </>
        );
    }
}