import {
    MDBCol,
    MDBRow
} from 'mdb-react-ui-kit';
import TweetView from '../components/twitter/TweetView'
import TwitterAccounts from '../components/twitter/TwitterAccounts';

export default function App() {
    return (
        <>
            <MDBRow>
                <MDBCol lg="6" md="12">
                    <TwitterAccounts />
                </MDBCol>
                <MDBCol lg="6" md="12">
                    <TweetView />
                </MDBCol>
            </MDBRow>
        </>
    );
}