import axios from "axios";
import AppConfig from "../../config";


export const getHandles = async () => {
    let data = await fetch(
        AppConfig.apiUrl + "/v1/twitter/users/all", {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("Bearer")}`
        },
        cache: "no-cache"
    });

    console.info("Received handles: ", data);
    return data.json();
}

export const deleteHandle = async (handle: string) => {

    let data = await axios({
        method: 'delete',
        baseURL: `${AppConfig.apiUrl}`,
        url: `/v1/twitter/users/remove/${handle}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("Bearer")}`
        }
    });
    return data.data;
}

export const addHandle = async (handle: string) => {
    let data = await axios({
        method: 'post',
        baseURL: `${AppConfig.apiUrl}`,
        url: `/v1/twitter/users/add/${handle}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("Bearer")}`
        }
    });
    console.info("Added handle ", data);
    return data.data;
}

export const getTweets = async (limit: number) => {
    let data = await axios({
        method: 'get',
        baseURL: `${AppConfig.apiUrl}`,
        url: `/v1/twitter/tweets?amount=${limit}&offset=0`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("Bearer")}`
        }
    });

    console.info("Received tweets: ", data);
    return data.data;
}

export const getInfo = async (handle: string) => {
    let data = await axios({
        method: 'get',
        baseURL: `${AppConfig.apiUrl}`,
        url: `v1/twitter/users/info/${handle}`
    });

    console.info("Received info: ", data);
    return data.data;
}