import React, { Component } from 'react';
import SingleTweet from './SingleTweet';
import { getTweets } from './TwitterAPI';

export default class TweetView extends Component {

    state = {
        tweets: [],
        refreshInterval: 5,
        schedule: false
    };

    componentDidMount = () => {
        this.setState({ schedule: true });
        setTimeout(() => {
            this.loadTweetsTimer();
        }, 1000);
    }


    loadTweets = () => {
        getTweets(25)
            .then(values => this.setState({ tweets: values.values }))
            .catch(reason => console.warn(reason));
    }

    loadTweetsTimer = () => {
        this.loadTweets();

        if (this.state.schedule) {
            setTimeout(() => {
                this.loadTweetsTimer();
            }, this.state.refreshInterval * 2500);
        }
    }

    render() {
        return (
            <>
                <div>
                    <br />
                    <br />
                    {this.state.tweets.map(x => <SingleTweet key={x["tweetId"]} image={x["authorInfo"]["imageUrl"]} handle={x["authorInfo"]["handle"]} content={x["content"]} tweetId={x["tweetId"]} />)}
                </div>
            </>
        )
    }
}
