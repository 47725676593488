import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import SpotifyPage from './pages/SpotifyPage';
import Login from './components/user/Login';
import UbiUser from './components/ubisoft/UbiUser';
import TwitterPage from './pages/TwitterPage';
import TwitterLookUp from './components/twitter/TwitterLookUp'
import HomePage from './pages/HomePage';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Switch >
        <Route path="/spotify" component={HomePage} />
        <Route path="/twitter" component={TwitterPage} />
        <Route path="/login" component={Login} />
        <Route path="/ubisoft" component={UbiUser} />
        <Route path="/callback" component={HomePage} />
        <Route path="/home" component={TwitterLookUp} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
