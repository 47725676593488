import axios from 'axios';
import { MDBBtn, MDBCard, MDBCardBody, MDBInput } from 'mdb-react-ui-kit';
import { useState } from 'react';
import AppConfig from '../../config';
import { useHistory } from 'react-router-dom';

export default function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    return (
        <div className='d-flex justify-content-center w-responsive text-center mx-auto p-3'>
            <MDBCard className='bg-dark'>
                <MDBCardBody className='text-spotify'>
                    <MDBInput className='mb-3' onChange={(e) => setUsername(e.target.value)} type='text' label='Username' contrast />
                    <MDBInput onChange={(e) => setPassword(e.target.value)} className='mb-3' type='password' label='Password' contrast />
                    <MDBBtn color='secondary' onClick={() => axios({
                        method: 'post',
                        baseURL: `${AppConfig.apiUrl}`,
                        url: '/v1/api/authentication/login',
                        data: {
                            username: `${username}`,
                            password: `${password}`
                        }
                    }).then((response) => { localStorage.clear(), localStorage.setItem("Bearer", response.data["token"]) }).then(() => console.log(localStorage.getItem("Bearer"))).then(() => history.push("/callback"))} block>
                        Login
                    </MDBBtn>
                </MDBCardBody>
            </MDBCard>
        </div>
    )
}