import axios from "axios";
import { useEffect, useState } from "react";

export const getReturnedParams = (hash: any) => {
    const stringAfter = hash.substring(1);
    const paramsInURL = stringAfter.split("&");
    const paramSplit = paramsInURL.reduce((accumulater: any, currentValue: any) => {
        const [key, value] = currentValue.split("=");
        accumulater[key] = value;
        return accumulater;
    }, {});
    return paramSplit;
}

export function useSpotify() {
    if (window.location.hash) {
        const {
            access_token,
            expires_in,
            token_type
        } = getReturnedParams(window.location.hash);
        console.log(`${access_token}:${expires_in}:${token_type}`);
        localStorage.clear();
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("expiresIn", expires_in);
        localStorage.setItem("tokenType", token_type);
    }
}

export function redirectToArtist(props: { id: string; }) {
    window.open(`https://open.spotify.com/artist/${props.id}?si=08d9b876f89348de`);
}


const CLIENT_ID = "4a6163cc789949a89a3eee6fa7fae77c";
const SPOTIFY_AUTHORIZE = "https://accounts.spotify.com/authorize";
//const REDIRECT_URL = "http://localhost:3000/spotify";
const REDIRECT_URL = "https://spotify.digneety.net/spotify"
const SPACE_DELIMITER = "%20";
const SCOPES = ["user-top-read"];
const SCOPES_URL_PARAM = SCOPES.join(SPACE_DELIMITER);

export const handleLogin = () => {
    window.location.href = `${SPOTIFY_AUTHORIZE}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&scope=${SCOPES_URL_PARAM}&response_type=token&show_dialog=true`;
    if (window.location.href.includes("access_token")) {
        setTimeout(() => {
            window.location.href = `${REDIRECT_URL}`
        }, 100);
    }
};

export const arrToString = (arr: []): string => {
    return arr.join(", ")
}

export const stringToString = (arr: string): string => {
    return arr.toString();
}