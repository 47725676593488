import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { Component } from "react";
import { addHandle, deleteHandle, getHandles } from "./TwitterAPI";

interface Accounts {
    array: any;
    handle: string;
    profileId: number;
    imageUrl: string;
}

export default class TwitterAccounts extends Component {

    state = {
        following: [],
        newAccountName: ""
    };

    fetchGuestlist = () => {
        getHandles()
            .then(handles => {
                console.log(handles)
                this.setState({ following: handles });
            });
    }


    addNewAccount = () => {
        addHandle(this.state.newAccountName)
            .then(response => alert(`Sucessfully added account with handle ${response.handle}`));

        this.setState({ following: [...this.state.following, this.state.newAccountName], newAccountName: "" });
        setTimeout(() => {
            this.fetchGuestlist();
        }, 1000);
    }

    deleteHandleFromGuestlist = (handle: any) => {
        deleteHandle(handle)
            .then(response => alert(`Sucessfully removed account with handle ${response.handle}`));

        this.setState({ following: [...this.state.following, this.state.newAccountName], newAccountName: "" });
        setTimeout(() => {
            this.fetchGuestlist();
        }, 1000);
    }

    newAccountNameChanged = (event: any) => {
        this.setState({ newAccountName: event.target.value });
    }

    componentDidMount = () => this.fetchGuestlist();

    render(): any {
        return (
            <>
                <MDBCard className="bg-dark text-spotify mt-5">
                    <MDBCardHeader>
                        Add a new Account to be automatically liked.
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol sm="9">
                                <MDBInput label='Handle' type='text' onChange={this.newAccountNameChanged} contrast />
                            </MDBCol>
                            <MDBCol sm="3">
                                <MDBBtn onClick={this.addNewAccount} color="success"><MDBIcon fas icon='plus' /> Add</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
                <br />
                <MDBCard className="text-spotify bg-dark">
                    <MDBCardHeader className="text-spotify">
                        Already added Accounts.
                    </MDBCardHeader>
                    <MDBCardBody className="text-spotify">
                        <MDBTable hoverable="true">
                            <MDBTableHead className="text-spotify">
                                <tr>
                                    <th scope='col'>Handle</th>
                                    <th scope='col'>Remove</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody className="text-spotify">
                                {this.state.following.map(x =>
                                    <tr key={x["imageUrl"]}>
                                        <td><img className="rounded-circle me-2" style={{ maxHeight: '2rem' }} src={x["imageUrl"]} />{x["handle"]}</td>
                                        <td>
                                            <MDBBtn floating color='danger' tag='a' onClick={e => { this.deleteHandleFromGuestlist(x["handle"]) }}>
                                                <MDBIcon fas icon='trash' />
                                            </MDBBtn>
                                        </td>
                                    </tr>)}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
            </>
        )
    }
}