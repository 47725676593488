import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBIcon,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBCollapse
} from 'mdb-react-ui-kit';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';

export default function Navbar() {
  const [showNavText, setShowNavText] = useState(false);
  const history = useHistory();

  return (
    <MDBNavbar className="bg-dark" expand='lg'>
      <MDBContainer fluid>
        <MDBNavbarBrand onClick={() => history.push("/home")}><img className="logo" src="https://apex-images.digneety.net/v1/fate-logo.png" alt="logo" /></MDBNavbarBrand>
        <MDBNavbarToggler
          type='button'
          data-target='#navbarText'
          aria-controls='navbarText'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNavText(!showNavText)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNavText}>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            {/* <MDBNavbarItem>
              <MDBNavbarLink className='text-light hover-shadow' active aria-current='page' onClick={() => history.push("/spotify")} >
                <MDBIcon fab icon='spotify' className="me-2" />
                Spotify
              </MDBNavbarLink>
            </MDBNavbarItem> */}
            <MDBNavbarItem>
              <MDBNavbarLink className='text-light hover-shadow' active aria-current='page' onClick={() => history.push("/twitter")} >
                <MDBIcon fab icon='twitter' className="me-2" />
                Twitter
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className='text-light hover-shadow' active aria-current='page' onClick={() => history.push("/ubisoft")} >
                <MDBIcon fas icon='poop' className="me-2" />
                Ubisoft
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
          {<MDBNavbarNav className="d-flex input-group w-auto" right>
            <MDBNavbarItem>
              <MDBNavbarLink className="text-light hover-shadow" aria-current='page' onClick={() => history.push("/login")}>
                {window.localStorage.getItem("accessToken") ?
                  (
                    <>
                      <MDBIcon fas icon='sign-out-alt' />
                    </>
                  ) :
                  (
                    <span>
                      <MDBIcon fas icon='sign-in-alt' />
                    </span>
                  )
                }
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>}
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
