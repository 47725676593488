import axios from "axios";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { useState } from "react";
import { useSpotify, arrToString, handleLogin } from "./SpotifyAPI";

interface Tracks {
    href: string;
    items: [];
    limit: number;
    next: string;
    offset: number;
    previous: any;
    total: number;
    external_urls: [];
    id: string;
    type: string;
    uri: string;
}

const TRACK_ENDPOINT = "https://api.spotify.com/v1/me/top/tracks?time_range=short_term&limit=10&offset=5";

const SpotifyTracks = () => {

    const [token, setToken] = useState<string | null>(null);
    const [data, setData] = useState<Tracks | null>(null);

    useEffect(() => {
        useSpotify();
        if (localStorage.getItem("accessToken")) {
            setToken(localStorage.getItem("accessToken"));
        }
    }, []);

    const handleTop = () => {
        axios.get(TRACK_ENDPOINT, {
            headers: {
                Authorization: "Bearer " + token,
            },
        }).then(response => {
            setData(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            {localStorage.getItem("accessToken") ?
                <>
                    <div className="text-center p-3">
                        <a style={{ textTransform: 'uppercase' }} key="topArt" className="ms-4 spotify" onClick={handleTop}>Get Top Tracks</a>
                    </div>
                </> :
                <div className="text-center p-3">
                    <a style={{ textTransform: 'uppercase' }} onClick={handleLogin} className="spotify hover-shadow"><MDBIcon fab icon='spotify' className="me-2" />Login with spotify</a>
                </div>}
            <div className="d-flex justify-content-center p-3 smooth-scroll">
                <MDBRow className="bg-dark">
                    {/* {data?.items ? data.items.map((track: any) => <MDBCol size="4" key={track.id}><img src={track.album.images[2].url} alt="cover" /></MDBCol>) : null}
                    {data?.items ? data.items.map((track: any) => <MDBCol size="4" className="text-spotify">{track.name}</MDBCol>) : null}
                    {data?.items ? data.items.map((track: any) => <MDBCol size="4" className="spotify-text">{track.artists.map((key: any) => key?.name ? key.name : arrToString(key.name))}</MDBCol>) : null} */}
                    {data?.items ? data.items.map((track: any) =>
                        <>
                            <MDBCol size="md-3" key={track.id}><img src={track.album.images[2].url} style={{ width: '4rem', height: '4rem' }} alt="cover" /></MDBCol>
                            <MDBCol size="md-3" className="text-spotify">{track.name}</MDBCol>
                            <MDBCol size="md-4" className="text-spotify">{track.artists.map((key: any) => key?.name ? key.name : arrToString(key.name))}</MDBCol>
                        </>) : null}
                </MDBRow>
            </div>
        </>
    )
}
export default SpotifyTracks;