import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import LoadHomePage from "../components/Modal";
import Spotify from "../components/spotify/Spotify";
import SpotifyArtists from "../components/spotify/SpotifyArtists";
import SpotifyTracks from "../components/spotify/SpotifyTracks";

export default function HomePage() {
    return (
        <>
            <SpotifyArtists />
            <SpotifyTracks />
            {/*             <Spotify /> */}
        </>
    )

}